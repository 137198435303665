<template>
  <b-container fluid>
    <b-row class="p-0 m-0" style="margin-bottom: 50px; margin-top: 20px; background: white">
      <div class="col-sm-12">
        <h4>{{ $t('sidebar.notifications') }}</h4><br><br>

        <div class="custom-control custom-checkbox mb-4">
          <input type="checkbox" class="custom-control-input" id="display_website1" v-model="display_website1">
          <label class="custom-control-label" for="display_website1">{{ $t('notifications.email_booking') }}</label>
          <vue-editor
            v-if="display_website1"
            id="editor1"
            v-model="editor1Content"
            useCustomImageHandler
            @image-added="handleImageAdded"
          >
            <div id="toolbar1" slot="toolbar">
              {{ $t('notifications.click_to_copy') }}
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[companyImage]]')">[[companyImage]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[companyName]]')">[[companyName]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[companyAddress]]')">[[companyAddress]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[companyPhone]]')">[[companyPhone]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[clientName]]')">[[clientName]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[services]]')">[[services]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[employeeName]]')">[[employeeName]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[bookingDate]]')">[[bookingDate]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[bookingTime]]')">[[bookingTime]]</button>
              <button class="ql-custom-button" @click="customButtonClick('[[bookingPaymentLink]]')">[[bookingPaymentLink]]</button>
            </div>
          </vue-editor>
        </div>

        <div class="custom-control custom-checkbox mb-4">
          <input type="checkbox" class="custom-control-input" id="display_website2" v-model="display_website2">
          <label class="custom-control-label" for="display_website2">{{
              $t('notifications.email_booking_reminder')
            }}</label>

          <vue-editor
            v-if="display_website2"
            id="editor2"
            v-model="editor2Content"
            useCustomImageHandler
            @image-added="handleImageAdded"
          >
            <div id="toolbar2" slot="toolbar">
              {{ $t('notifications.click_to_copy') }}
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[companyImage]]')">[[companyImage]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[companyName]]')">[[companyName]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[companyAddress]]')">[[companyAddress]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[companyPhone]]')">[[companyPhone]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[clientName]]')">[[clientName]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[services]]')">[[services]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[employeeName]]')">[[employeeName]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[bookingDate]]')">[[bookingDate]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[bookingTime]]')">[[bookingTime]]</button>
              <button class="ql-custom-button" @click="customButtonClick('[[bookingPaymentLink]]')">[[bookingPaymentLink]]</button>
            </div>
          </vue-editor>
        </div>

        <div class="custom-control custom-checkbox mb-4">
          <input type="checkbox" class="custom-control-input" id="display_website3" v-model="display_website3">
          <label class="custom-control-label" for="display_website3">{{ $t('notifications.sms_booking') }}</label>
            <div id="toolbar3" slot="toolbar">
              {{ $t('notifications.click_to_copy') }}
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[companyName]]')">[[companyName]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[companyAddress]]')">[[companyAddress]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[companyPhone]]')">[[companyPhone]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[clientName]]')">[[clientName]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[services]]')">[[services]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[employeeName]]')">[[employeeName]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[bookingDate]]')">[[bookingDate]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[bookingTime]]')">[[bookingTime]]</button>
              <button class="ql-custom-button" @click="customButtonClick('[[bookingPaymentLink]]')">[[bookingPaymentLink]]</button>
            </div>
          <textarea v-if="display_website3" id="editor3" style="width:100%; height:150px;border:1px solid grey;" v-model="editor3Content"></textarea>
        </div>
        <div class="custom-control custom-checkbox mb-4">
          <input type="checkbox" class="custom-control-input" id="display_website4" v-model="display_website4">
          <label class="custom-control-label" for="display_website4">{{
              $t('notifications.sms_booking_reminder')
            }}</label>
            <div id="toolbar4" slot="toolbar">
              {{ $t('notifications.click_to_copy') }}
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[companyName]]')">[[companyName]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[companyAddress]]')">[[companyAddress]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[companyPhone]]')">[[companyPhone]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[clientName]]')">[[clientName]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[services]]')">[[services]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[employeeName]]')">[[employeeName]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[bookingDate]]')">[[bookingDate]]</button>
              <button class="ql-custom-button mr-2" @click="customButtonClick('[[bookingTime]]')">[[bookingTime]]</button>
              <button class="ql-custom-button" @click="customButtonClick('[[bookingPaymentLink]]')">[[bookingPaymentLink]]</button>

            </div>
          <textarea v-if="display_website4" id="editor4" style="width:100%; height:150px;border:1px solid grey;" v-model="editor4Content"></textarea>
        </div>

        <div class="custom-control custom-checkbox mb-4 d-none">
          <input type="checkbox" class="custom-control-input" id="display_website5" v-model="display_website5">
          <label class="custom-control-label" for="display_website5">{{ $t('notifications.send_daily') }}</label>
        </div>

        <div class="custom-control custom-checkbox mb-4 d-none">
          <input type="checkbox" class="custom-control-input" id="display_website6" v-model="display_website6">
          <label class="custom-control-label" for="display_website6">{{
              $t('notifications.send_daily_reports')
            }}</label>
        </div>

        <b-button @click="saveNotification" variant="primary" class="mr-2">{{ $t('timeoff.submit') }}</b-button>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import Axios from 'axios'
import { API } from '@/config/api.config'
import { core } from '@/config/pluginInit'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'Program',
  components: {
    VueEditor
  },
  mounted () {
    core.index()
    this.getCurrentNotification()
  },
  data () {
    return {
      apiToken: localStorage.getItem('api_token') || '',
      customToolbar: [
        ['aaa']
      ],
      editor1Content: '',
      editor2Content: '',
      editor3Content: '',
      editor4Content: '',
      display_website1: false,
      display_website2: false,
      display_website3: false,
      display_website4: false,
      display_website5: false,
      display_website6: false
    }
  },
  methods: {
    handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData()
      formData.append('image', file)
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SAVE_COMPANY_PICTURE, formData)
        .then(result => {
          const url = result.data.url
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    customButtonClick (theType) {
      navigator.clipboard.writeText(theType)
      core.showSnackbar('success', this.$t('notifications.copied'))
    },
    getCurrentNotification () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.get(API.API_GET_COMPANY_NOTIFICATIONS)
        .then((response) => {
          if (response.data.status === 'success') {
            if (response.data.notifications && response.data.notifications.messages) {
              const notifications = JSON.parse(response.data.notifications.messages)
              this.display_website1 = notifications.email
              this.editor1Content = notifications.email_text
              this.display_website2 = notifications.email_reminder
              this.editor2Content = notifications.email_reminder_text
              this.display_website3 = notifications.sms
              this.editor3Content = notifications.sms_text
              this.display_website4 = notifications.sms_reminder
              this.editor4Content = notifications.sms_reminder_text
              this.display_website5 = notifications.send_booking
              this.display_website6 = notifications.send_reports
            }
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    saveNotification () {
      const formData = {
        notifications: {
          email: this.display_website1,
          email_text: this.editor1Content,
          email_reminder: this.display_website2,
          email_reminder_text: this.editor2Content,
          sms: this.display_website3,
          sms_text: this.editor3Content,
          sms_reminder: this.display_website4,
          sms_reminder_text: this.editor4Content,
          send_booking: this.display_website5,
          send_reports: this.display_website6
        }
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_ADD_COMPANY_NOTIFICATIONS, formData)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('notifications.company_updated'))
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    }
  }
}
</script>
